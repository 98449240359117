import { useEffect } from 'react'
import { useGetOfferUrlWithUtm } from './useGetOfferUrlWithUtm'
import getPostBack from '../api/postback/postback'
export const useComeBacker = () => {
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  useEffect(() => {
    if (window.history && window.history.pushState) {
      window.addEventListener(
        'load',
        async function () {
          window.history.pushState(-1, null)
          window.history.pushState(0, null)
          window.history.pushState(1, null)
          window.history.go(-1)
          window.addEventListener(
            'popstate',
            async (event) => {
              if (event.state === -1) {
                const url = getOfferUrlUtm(
                  'https://gl.guruleads.ru/click/23000/2445?erid=LjN8K25g7&sub1=cmb',
                )
                const result = await getPostBack({ offerId: '80' })
                if (result) {
                  window.location.href = url.includes('?')
                    ? `${url}&click_id=${result}`
                    : `${url}?&click_id=${result}`
                } else {
                  window.location.href = url
                }
              }
            },
            false,
          )
        },
        false,
      )
    }
  }, [])
}
